/* eslint-disable no-loop-func */
import React, { Component } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Card,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Tabs,
  Tab,
  ListItemSecondaryAction,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Add,
  BarChart,
  Clear,
  Close,
  KeyboardArrowRight,
  Logout,
  PeopleAltTwoTone,
  Refresh,
  Save,
} from "@mui/icons-material";
import "../App.css";
import axios from "../axios";
import { getAuthHeaders } from "../helpers";
import TotalChart from "../components/chart";
import VolumeChart from "../components/volume_chart";
import TotalStoreChart from "../components/total_chart";
import { MobileDatePicker } from "@mui/x-date-pickers/";
import moment from "moment";
import TickerTable from "../components/ticker_table";

class HomePage extends Component {
  shouldNotUpdate = false;

  departments = {
    sales: {
      name: "Sales",
      count: 0,
      branches: { status: { name: "Status", count: 0 }, marketing: { name: "Marketing", count: 0 }, },
    },
    service: {
      name: "Service",
      count: 0,
      branches: {
        status: { name: "Status", count: 0, requiresAdvisor: true },
        appt: { name: "Appt", count: 0, requiresAdvisor: true },
      },
    },
    parts: {
      name: "Parts",
      count: 0,
      branches: { status: { name: "Status", count: 0 } },
    },
    fi: {
      name: "F & I",
      count: 0,
      branches: { status: { name: "Status", count: 0 } },
    },
    bshop: {
      name: "Body Shop",
      count: 0,
      branches: { 
        status: { name: "Status", count: 0 }, 
        booked: { name: "Booked", count: 0 }, 
      },
    },
    ql: {
      name: "Quick Lane",
      count: 0,
      branches: {
        status: { name: "Status", count: 0, requiresAdvisor: true },
        appt: { name: "Appt", count: 0, requiresAdvisor: true },
      },
    },
  };

  tabs = [
    { key: "service", name: "Service" },
    { key: "ql", name: "Quick Lane" },
  ];

  stores = {
    Airdrie:{bshop:1,ql:1},
    Olds:{bshop:0,ql:1,},
    Innisfail:{bshop:0,ql:0},
    "Red Deer":{bshop:1,ql:1},
    Canmore:{bshop:0,ql:1},
    "North Van":{bshop:1,ql:0},
    Richmond:{bshop:0,ql:0},
    "Kamloops Ford":{bshop:1,ql:0,booked:1},
    "Dearborn Ford":{bshop:0,ql:0},
    "Kia":{bshop:0,ql:0},
    "Mazda":{bshop:0,ql:0},
    "Santa Monica":{bshop:0,ql:0},
    "Evergreen Ford":{bshop:0,ql:0},
    "Evergreen Chev":{bshop:0,ql:0},
    "Clark Nissan":{bshop:1,ql:1},
    "Clark Hyundai":{bshop:1,ql:1},
    "Salmon Arm Toyota":{bshop:0,ql:0},
  };

  state = {
    chartDialog: false,
    advisorDialog: false,
    addAdvisorDialog: false,
    savingAdvisor: true,
    chartDataLoading: true,
    tickerDataLoading: true,
    startDate: moment().subtract(5, "day"),
    endDate: moment(),
    store: "Airdrie",
    chartType: "total",
    department: "sales",
    tab: 0,
    dialogType: "chart",
    volumePerHour: null,
    storeTotalData: null,
    advisors: [],
    advisorSelection: null,
    newAdvisorName: "",
    chartData: null,
    data: null,
    advisorsData: null,
    total: 0,
    tickerLastRefresh:null,
  };
  departmentChartCounter = 0;
  volumeChartCounter = 0;
  storeTotalChartCounter = 0;

  getUserFromSession = () => {
    try {
      return JSON.parse(sessionStorage.getItem("user"));
    } catch (error) {
      return {};
    }
  };

  user = this.getUserFromSession();

  handleChangeDepartment = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.getChartData(true)
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.history.go("/");
  };

  handleDateChange = (newDate, type = "startDate") => {
    this.setState({ [type]: newDate }, () => {
      this.getChartData(true);
      this.getVolumePerHourData(true);
    });
  };

  handleStoreChange = (e) => {
    this.setState({ store: e.target.value }, () => {
      localStorage.setItem("store", e.target.value);
      this.getTickerData(true);
      this.getChartData(true);
      this.getVolumePerHourData();
    });
  };

  roundTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // date.setMinutes(date.getMinutes() - (date.getMinutes() % 30));
    if (date.getMinutes() < 15) {
      date.setMinutes(0);
    } else if (date.getMinutes() < 45) {
      date.setMinutes(30);
    } else {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }
    date.setDate(1);
    date.setMonth(1);
    date.setYear(2000);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  };

  getChartData = async (refreshOnly = false) => {
    if (this.shouldNotUpdate) return;
    try {
      if (refreshOnly) this.setState({ refreshOnly: true });
      else this.setState({ chartDataLoading: true });

      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        department: this.state.department,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        endDate: this.state.endDate.format("YYYY-MM-DD"),
      };
      const result = await axios.post(`/v1/calls/`, body, getAuthHeaders());
      var items = {};
      var advisorsData = {};
      var labels = [];
      var total = 0;
      result.data.forEach((row) => {
        if (row.extra === "") row.extra = "general";
        if (!items[row.extra]) {
          items[row.extra] = 1;
        } else {
          items[row.extra]++;
          total += items[row.extra];
        }

        if (row.advisor !== "") {
          if (!advisorsData[row.advisor]) {
            advisorsData[row.advisor] = {};
          }
          if (!advisorsData[row.advisor][row.extra]) {
            advisorsData[row.advisor][row.extra] = 1;
            labels.push(row.extra);
          } else advisorsData[row.advisor][row.extra]++;
        }

        // if (this.state.department !== row.extra) items[this.state.department]++;
      });
      this.departmentChartCounter++;
      if (Object.keys(advisorsData).length === 0) advisorsData = null;
      console.log('items');
      console.log(items);
      this.setState({
        chartDataLoading: false,
        refreshOnly: false,
        chartData: items,
        advisorsData,
        labels,
        total,
        rawData:result.data
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ chartDataLoading: false, refreshOnly: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  checkLastUpdate = async () => {
    try {
      const result = await axios.get(`/v1/calls/last-update`,  getAuthHeaders());
      console.log('last update checker : ',result.data[0].lastupdate)
      if(this.state.lastUpdateCheck === result.data[0].lastupdate) return;
      console.log('last update changed')
      this.setState({lastUpdateCheck :result.data[0].lastupdate});
      this.getTickerData(true)

    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };


  getTickerData = async (refreshOnly = false) => {
    if (this.shouldNotUpdate) return;
    if (!refreshOnly) this.setState({ tickerDataLoading: true });
    try {
      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      const result = await axios.post(`/v1/calls/`, body, getAuthHeaders());
      var data = JSON.parse(JSON.stringify(this.departments));
      console.log('data',data)
      console.log('result',result.data)
      result.data.forEach((row) => {
        console.log('row',row)
        data[row.department.toLowerCase()].count++;
        if (row.extra !== "") data[row.department.toLowerCase()].branches[row.extra].count++;
      });
      this.setState({
        tickerDataLoading: false,
        data,
        refreshOnly: false,
        tickerLastRefresh:moment()
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ chartDataLoading: false, refreshOnly: false });
      if(!refreshOnly){
        if (error.response) alert(error.response.data.message);
        else alert(error);
      }
      
    }
  };

  getVolumePerHourData = async (refreshOnly = false) => {
    if (refreshOnly) this.setState({ refreshOnly: true });
    try {
      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        endDate: this.state.endDate.format("YYYY-MM-DD"),
      };
      const result = await axios.post(`/v1/calls/`, body, getAuthHeaders());
      const volumePer30Minutes = {};
      var storeTotalData = JSON.parse(JSON.stringify(this.departments));

      result.data.forEach((row) => {
        let roundedTimestamp = this.roundTimestamp(row.timestamp);
        var time = moment(roundedTimestamp).utc().format('HH:mm');
        if (!volumePer30Minutes[time]) {
          volumePer30Minutes[time] = {};
        }
        if (!volumePer30Minutes[time][row.department.toLowerCase()]) {
          volumePer30Minutes[time][row.department.toLowerCase()] = 0;
        }
        volumePer30Minutes[time][row.department.toLowerCase()]++;

        //for storeTotal chart and table data
        storeTotalData[row.department.toLowerCase()].count++;
      });


      let timeArray = Object.entries(volumePer30Minutes);

timeArray.sort(function(a, b) {
  
  let aTime = new Date("1970-01-01 " + a[0]);
  let bTime = new Date("1970-01-01 " + b[0]);
  return aTime - bTime;
});

let sortedvolumePer30Minutes = Object.fromEntries(timeArray);

      // console.log('volumePer30Minutes')
      // console.log(JSON.stringify(volumePer30Minutes))
      // console.log('sortedvolumePer30Minutes')
      // console.log(JSON.stringify(sortedvolumePer30Minutes))
      // console.log(Object.keys(sortedvolumePer30Minutes))
      this.volumeChartCounter++;
      this.setState({
        refreshOnly: false,
        volumePer30Minutes:sortedvolumePer30Minutes,
        storeTotalData,
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ refreshOnly: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  getAdvisors = async () => {
    try {
      this.setState({ advisorsLoading: true });
      const result = await axios.get(`/v1/calls/advisor`, getAuthHeaders());
      this.setState({ advisors: result.data, savingAdvisor: false });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ advisorsLoading: false, savingAdvisor: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  deleteAdvisor = async (name, ID) => {
    try {
      let allowDelete = window.confirm(`Do you want to delete ${name} ?`);
      if (!allowDelete) return;
      this.setState({ deletingAdvisorID: ID });
      var advisors = this.state.advisors.filter((advisor) => advisor.ID !== ID);

      const result = await axios.delete(
        `/v1/calls/advisor/${ID}`,
        getAuthHeaders()
      );
      this.setState({ advisors });
      this.setState({ deletingAdvisorID: null });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ advisorsLoading: false, savingAdvisor: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  componentDidMount() {
    this.getAdvisors();
    let store = localStorage.getItem("store");
    if (!store) store = "Airdrie";
    this.setState({ store }, () => {
      this.getTickerData();
      this.getChartData();
      this.getVolumePerHourData();
    });
    this.interval = setInterval(() => {
      this.checkLastUpdate();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleTickerCountUpdate = async ({
    type = "+",
    department,
    branch,
    advisor,
  }) => {
    try {
      this.shouldNotUpdate = true;
      // this.setState({ advisorSelection: null, refreshOnly: true }, () => {});
      
      var body = {
        type,
        department,
        extra: branch,
        advisor,
        store: this.state.store,
      };
       console.log(body);
      const result = await axios.put(`/v1/calls/`, body, getAuthHeaders());
      this.shouldNotUpdate = false;
      this.getTickerData(true);
    } catch (error) {
      this.shouldNotUpdate = false;
    }
  };

  addAdvisor = async () => {
    try {
      const { newAdvisorName } = this.state;
      if (newAdvisorName.trim() === "")
        return alert("Advisor name cannot be empty");
      this.setState({ savingAdvisor: true });
      var body = {
        department: this.tabs[this.state.tab].key,
        advisor: newAdvisorName,
        store: this.state.store,
      };
      const result = await axios.post(
        `/v1/calls/advisor`,
        body,
        getAuthHeaders()
      );
      this.setState({ addAdvisorDialog: false, newAdvisorName: "" });
      this.getAdvisors();
    } catch (error) {
      this.setState({ savingAdvisor: false });
    }
  };

  advisorSelectionHandler = ({ department, branch, type = "+" }) => {
    this.setState({ advisorSelection: { department, branch, type } });
  };

  render() {
    return (
      <div>
        <Grid container justifyContent={"space-between"}>
          <h4>Hi {this.user.displayName}</h4>
          <div>
            <Button onClick={() => this.logout()} endIcon={<Logout />}>
              Logout
            </Button>
          </div>
        </Grid>

        {this.state.tickerDataLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              justifyContent={"center"}
              spacing={4}
              alignItems={"center"}
            >
              <Grid item>
                <Select
                  label="Store"
                  style={{ minWidth: 150 }}
                  name="store"
                  value={this.state.store}
                  onChange={this.handleStoreChange}
                >
                  {Object.keys(this.stores).map((store) => (
                    <MenuItem value={store}>{store}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              style={{ marginBottom: 16, marginTop: 8 }}
              container
              justifyContent={"center"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Button
                  startIcon={<BarChart />}
                  variant="contained"
                  onClick={() => this.setState({ chartDialog: true },()=>this.getChartData(true))}
                >
                  VIEW CHART
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<PeopleAltTwoTone />}
                  variant="contained"
                  onClick={() => this.setState({ advisorDialog: true })}
                >
                  Manage Advisors
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ textAlign: "center", marginTop: 16 }}
              justifyContent="center"
              alignItems={"center"}
            >
             
              <Grid item xs={12} md={3}>
                <Card>
                  <table style={{ width: "100%", borderSpacing: 0 }}>
                    <tbody>
                      {Object.keys(this.departments).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map(
                        (department, index) => {
                          return (
                            <>
                              <tr
                                style={{
                                  background:
                                    index % 2 === 0 ? "#f2f2f2" : "#fff",
                                    lineHeight:0
                                }}
                              >
                                <td
                                  style={{
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: "left",
                                    fontSize:14,
                                    lineHeight:0
                                  }}
                                >
                                  <h2 >{this.departments[department].name}</h2>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      this.handleTickerCountUpdate({
                                        type: "-",
                                        department: department,
                                      })
                                    }
                                    variant="contained"
                                    style={{
                                      fontSize: 30,
                                      height: 30,
                                      background: "#3e95f0",
                                    }}
                                  >
                                    -
                                  </Button>
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: "center",
                                    fontSize: 30,
                                  }}
                                >
                                  {this.state.data[department].count ?? 0}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      this.handleTickerCountUpdate({
                                        type: "+",
                                        department: department,
                                      })
                                    }
                                    variant="contained"
                                    style={{
                                      fontSize: 30,
                                      height: 30,
                                      background: "#3e95f0",
                                    }}
                                  >
                                    +
                                  </Button>
                                </td>
                              </tr>
                              {Object.keys(
                                this.departments[department].branches
                              ).filter(
                                (branchType=>
                                  (branchType!=='booked') || 
                                (branchType==='booked' && this.stores[this.state.store].booked) 
                             
                                )

                              ).map((branch) => {
                                return (
                                  <tr
                                    style={{
                                      background:
                                        index % 2 === 0 ? "#f2f2f2" : "#fff",
                                        lineHeight:0
                                    }}
                                  >
                                    <td
                                      style={{
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        textAlign: "right",
                                        lineHeight:0
                                      }}
                                    >
                                      <span
                                        style={{
                                          paddingLeft: 16,
                                          fontSize: 18,
                                          color: "#5b3cb4",
                                          
                                        }}
                                      >
                                        {
                                          this.departments[department].branches[
                                            branch
                                          ].name
                                        }
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <div>
                                        <Button
                                        onClick={() =>
                                          this.departments[department].branches[
                                            branch
                                          ].requiresAdvisor
                                            ? this.advisorSelectionHandler({
                                                department: department,
                                                branch: branch,
                                                type: "-",
                                              })
                                            : this.handleTickerCountUpdate({
                                                type: "-",
                                                department: department,
                                                branch: branch,
                                              })
                                        }
                                        variant="contained"
                                        style={{
                                          fontSize: 30,
                                          height: 30,
                                          backgroundColor: "#5b3cb4",
                                        }}
                                      >
                                        -
                                      </Button></div>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        textAlign: "center",
                                        fontSize: 30,
                                      }}
                                    >
                                      {
                                        this.state.data[department].branches[
                                          branch
                                        ].count
                                      }
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          this.departments[department].branches[
                                            branch
                                          ].requiresAdvisor
                                            ? this.advisorSelectionHandler({
                                                department: department,
                                                branch: branch,
                                                type: "+",
                                              })
                                            : this.handleTickerCountUpdate({
                                                type: "+",
                                                department: department,
                                                branch: branch,
                                              })
                                        }
                                        variant="contained"
                                        style={{
                                          fontSize: 30,
                                          height: 30,
                                          background: "#5b3cb4",
                                        }}
                                      >
                                        +
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </Card>
              </Grid>
              {this.state.tickerLastRefresh && <Grid style={{marginTop:8}} item xs={12}>
              Last Updated : {this.state.tickerLastRefresh.format('hh:mm A')} 
            </Grid>}
            </Grid>
            <Dialog
              fullScreen
              open={this.state.chartDialog}
              onClose={() => this.setState({ chartDialog: false })}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <h4>Calls Ticker for {this.state.store?.toUpperCase()}</h4> */}
                  <Select
                    style={{
                      minWidth: 150,
                      margin: 2,
                      height: 36,
                      background: "white",
                    }}
                    name="chartType"
                    id="chartType"
                    value={this.state.chartType}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="total">Calls Ticker Chart</MenuItem>
                    <MenuItem value="volume">Volume Per Hour</MenuItem>
                    <MenuItem value="store">Store Total</MenuItem>
                  </Select>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ chartDialog: false })}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Grid
                  style={{ marginBottom: 16 }}
                  container
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Grid item>
                    <MobileDatePicker
                      //minDate from helpers.js
                      label="Start Date"
                      allowSameDateSelection
                      disableCloseOnSelect={false}
                      showToolbar={false}
                      showTodayButton
                      inputFormat="yyyy-MM-DD"
                      name="date"
                      disableFuture
                      views={["year", "month", "day"]}
                      value={this.state.startDate}
                      onChange={(date) =>
                        this.handleDateChange(date, "startDate")
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <MobileDatePicker
                      label="End Date"
                      //minDate from helpers.js
                      allowSameDateSelection
                      disableCloseOnSelect={false}
                      showToolbar={false}
                      showTodayButton
                      inputFormat="yyyy-MM-DD"
                      name="date"
                      disableFuture
                      views={["year", "month", "day"]}
                      value={this.state.endDate}
                      onChange={(date) =>
                        this.handleDateChange(date, "endDate")
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginBottom: 16 }}
                  container
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Grid item>
                    <Select
                      style={{ minWidth: 150 }}
                      name="store"
                      value={this.state.store}
                      onChange={this.handleStoreChange}
                    >
                  {Object.keys(this.stores).map((store) => (
                    <MenuItem value={store}>{store}</MenuItem>
                  ))}
                    </Select>
                  </Grid>
                  {this.state.chartType === "total" && (
                    <Grid item>
                      <Select
                        style={{ minWidth: 150 }}
                        name="department"
                        id="department"
                        value={this.state.department}
                        onChange={this.handleChangeDepartment}
                      >
                        {Object.keys(this.departments).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map((department) => 
                             <MenuItem value={department}>
                            {this.departments[department].name}
                          </MenuItem>
                          )}
                      </Select>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  style={{ marginBottom: 16 }}
                  container
                  justifyContent={"center"}
                  spacing={2}
                >
                  {this.state.chartType === "volume" ? (
                    <Grid item xs={12} md={9}>
                      <Card>
                        {this.state.volumePer30Minutes && (
                          <VolumeChart
                            departments={Object.keys(this.departments).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map(
                              (department) => {
                                return {
                                  name: this.departments[department].name,
                                  key: department,
                                };
                              }
                            )}
                            data={this.state.volumePer30Minutes}
                            labels={Object.keys(this.state.volumePer30Minutes).map(time=>moment('1997-01-01 '+time).format('hh:mmA'))}
                            key={this.volumeChartCounter}
                          />
                        )}
                      </Card>
                    </Grid>
                  ) : this.state.chartType === "total" ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <TotalChart
                            title={this.state.department + " chart"}
                            stacked={true}
                            key={this.departmentChartCounter}
                            labels={[
                              "Total : " +
                                Object.values(
                                  this.state.chartData ?? [0]
                                ).reduce((total, num) => total + num, 0),
                            ]}
                            data={this.state.chartData}
                          />
                        </Card>
                      </Grid>
                      {this.state.advisorsData && (
                        <Grid item xs={12} md={6}>
                          <Card>
                            <TotalChart
                              title={this.state.department + " advisor chart"}
                              hasAdvisors={true}
                              stacked={false}
                              key={this.departmentChartCounter}
                              labels={
                                this.state.chartData
                                  ? Object.keys(this.state.chartData).filter(
                                      (item) => item !== "general"
                                    )
                                  : []
                              }
                              data={this.state.advisorsData ?? []}
                            />
                          </Card>
                        </Grid>
                      )}
                      <Grid item>
                      <TickerTable data={this.state.rawData} title={`${this.state.department.toUpperCase()} DATA`}/>
                      </Grid>
                    </>
                  ) : (
                    <>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <TotalStoreChart
                          title={this.state.store + " chart"}
                          // key={this.storeTotalChartCounter}
                          labels={Object.keys(this.state.storeTotalData).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map(
                            (department) =>
                              this.state.storeTotalData[department].name
                          )}
                          data={Object.keys(this.state.storeTotalData).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map(
                            (department) => {
                              return {
                                count:
                                  this.state.storeTotalData[department].count,
                                name: this.state.storeTotalData[department]
                                  .name,
                              };
                            }
                          )}
                        />
                      </Card>
                    </Grid>
                    <Grid item>
                    <Card>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell><b>Department</b></TableCell>
                              <TableCell><b>Total</b></TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                          {Object.keys(this.state.storeTotalData).filter(department=>(department!=='ql' && department!=='bshop') || this.stores[this.state.store][department]).map(department=>{
                            return (<TableRow>
                              <TableCell>{this.state.storeTotalData[department].name}</TableCell>
                              <TableCell>{this.state.storeTotalData[department].count}</TableCell>
                          </TableRow>);
                          })}
                        
                          </TableBody>
                        </Table>
                      </Card>
                    </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.advisorSelection !== null}
              maxWidth={"xs"}
              fullWidth
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>
                    CHOOSE{" "}
                    {this.state?.advisorSelection?.department?.toUpperCase()}{" "}
                    ADVISOR for{" "}
                    {this.state?.advisorSelection?.branch?.toUpperCase()}
                  </h4>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ advisorSelection: null })}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <List>
                  {this.state.advisors
                    .filter(
                      (advisor) =>
                        advisor.store === this.state?.store &&
                        advisor.department.toLowerCase() ===
                          this.state?.advisorSelection?.department
                    )
                    .map((advisor) => (
                      <ListItemButton
                        onClick={() =>
                          this.handleTickerCountUpdate({
                            type: this.state.advisorSelection.type,
                            department: this.state.advisorSelection.department,
                            branch: this.state.advisorSelection.branch,
                            advisor: advisor.advisor,
                          })
                        }
                      >
                        <ListItemText>{advisor.advisor}</ListItemText>
                        <ListItemIcon>
                          <KeyboardArrowRight />
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                </List>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.advisorDialog}
              maxWidth={"xs"}
              fullWidth
              onClose={() => this.setState({ advisorDialog: false })}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>{this.state?.store?.toUpperCase()} ADVISORS</h4>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ advisorDialog: null })}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, val) => this.setState({ tab: val })}
                  value={this.state.tab}
                >
                  {this.tabs.map((tab) => (
                    <Tab label={tab.name} />
                  ))}
                </Tabs>
                <List>
                  {this.state.advisors
                    .filter(
                      (advisor) =>
                        advisor.store === this.state?.store &&
                        advisor.department.toLowerCase() ===
                          this.tabs[this.state.tab].key
                    )
                    .map((advisor) => (
                      <ListItem>
                        <ListItemText>{advisor.advisor}</ListItemText>
                        <ListItemSecondaryAction>
                          {this.state.deletingAdvisorID === advisor.ID ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              color="error"
                              onClick={() =>
                                this.deleteAdvisor(advisor.advisor, advisor.ID)
                              }
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
                <Button
                  onClick={() => this.setState({ addAdvisorDialog: true })}
                  startIcon={<Add />}
                >
                  Add Advisor
                </Button>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.addAdvisorDialog}
              onClose={() => this.setState({ addAdvisorDialog: false })}
            >
              <DialogContent>
                <Grid container justifyContent={"center"} spacing={8}>
                  <Grid item>
                    <TextField
                      required
                      label="Name"
                      name="newAdvisorName"
                      onBlur={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {this.state.savingAdvisor ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button
                      color="error"
                      variant="contained"
                      startIcon={<Clear />}
                      onClick={() => this.setState({ addAdvisorDialog: false })}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.addAdvisor}
                      startIcon={<Save />}
                    >
                      Save
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.refreshOnly}>
              <DialogContent>
                <Grid container justifyContent={"center"} spacing={8}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    );
  }
}

export default HomePage;
