import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";

const columns = [
  //   {
  //     name: "department",
  //     label: "Department",
  //     options: {
  //       filter: true,
  //       sort: true,
  //     },
  //   },
  {
    name: "timestamp",
    label: "Time",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => {
        return moment(data).utc().format("YYYY-MM-DD hh:mmA");
      },
    },
  },
  {
    name: "username",
    label: "User",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "extra",
    label: "Branch",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "advisor",
    label: "Advisor",
    options: {
      filter: true,
      sort: true,
    },
  },
];
const options = {
  selectableRows: "none",
  print: false,
  viewColumns: false,
  filterType: "dropdown",
};
function TickerTable({ data, title }) {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

export default TickerTable;
