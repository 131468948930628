export function getAuthHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": sessionStorage.getItem("jwt"),
    },
  };
}

export function getStoreNameFromKey(storeKey) {
  console.log("storeKey");
  console.log(storeKey);
  return storeNames[storeKey] != null
    ? storeNames[storeKey]
    : storeKey.toString().toUpperCase().replace("_", " ");
}

export const storeSettingWrappedName = {
  airdrie: "airdrie",
  olds: "olds",
  innisfail: "innisfail",
  reddeer: "reddeer",
  canmore: "canmore",
  kfl: "kamloops_ford",
  kia: "kamloops_kia",
  mazda: "kamloops_mazda",
  dearborn: "dearborn_ford",
  northvan: "north_van",
  richmond: "richmond",
  santamonica: "santa_monica",
  nissan: "clark_nissan",
  hyundai: "clark_hyundai",
  evergreen_chevy: "evergreen_chevy",
  evergreen_ford: "evergreen_ford",
  kamloops_suv: "kamloops_suv",
};

export const mappedMonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const minDate = "2022-02-01";
export const minYear = 2022;

const storeNames = {
  airdrie: "Airdrie Ford",
  olds: "Olds Ford",
  canmore: "Canmore Ford",
  dearborn_ford: "Dearborn Ford",
  innisfail: "Innisfail Chrysler",
  reddeer: "Red Deer Ford",
  kamloops_ford: "Kamloops F/L",
  kamloops_kia: "Kamloops Kia",
  kamloops_mazda: "Kamloops Mazda",
  north_van: "North Van F/L",
  richmond: "Richmond Ford",
  santa_monica: "Santa Monica F/L",
  clark_nissan: "Clark Nissan",
  clark_hyundai: "Clark Hyundai",
  evergreen_chevy: "Evergreen Chev",
  evergreen_ford: "Evergreen F/L",
  kamloops_suv: "Kamloops SUV",
};
