import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "../axios";
import { getAuthHeaders } from "../helpers";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="#">
//         CamClarkGroup - Ali Javed
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const theme = createTheme();

export default class LoginPage extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: false });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
      remember: data.get("remember"),
    });

    this.authenticateUser(
      data.get("email"),
      data.get("password"),
      data.get("remember") != null
    );
  };

  authenticateUser = async (email, password, remember) => {
    try {
      this.setState({ loading: true });
      const body = {
        email,
        password,
      };
      const result = await axios.post("/v1/users/auth", body, getAuthHeaders());
      console.log("result");
      console.log(result);

      localStorage.clear();
      sessionStorage.clear();

      if (remember) {
        localStorage.setItem("jwt", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.user));
      }
      sessionStorage.setItem("jwt", result.data.token);
      sessionStorage.setItem("user", JSON.stringify(result.data.user));
      this.setState({ loading: false });
      this.props.onSuccess();
      this.props.history.push("/");
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ loading: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              style={{ textAlign: "center" }}
              component="h1"
              variant="h5"
            >
              Cam Clark Automotive Group
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              component="h1"
              variant="h5"
            >
              Calls Ticker
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} sx={{ mt: 4 }}>
              <TextField
                helperText="e.g. username@camclarkford.ca"
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                helperText="Hint : your work computer/email password"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox name="remember" value="1" color="primary" />}
                label="Remember me"
              />
              {this.state.loading ? (
                <Grid container justifyContent={"center"}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              )}
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </ThemeProvider>
    );
  }
}
